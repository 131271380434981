import { Redirect, Route, Switch } from 'react-router-dom';

import AdminRoute from './AdminRoute';
import AdminCustomerLookupsList from './pages/admin/customer-lookups';
import AdminUsersEdit from './pages/admin/users/edit';
import AdminUsersList from './pages/admin/users/list';
import AuthAcceptInvite from './pages/auth/accept-invite';
import AuthForgotPassword from './pages/auth/forgot-password';
import AuthLogin from './pages/auth/login';
import AuthResetPassword from './pages/auth/reset-password';
import CustomerLookup from './pages/console/customer-lookup';
import UserSettings from './pages/console/user/settings';
import NotFound from './pages/not-found';
import RestrictedRoute from './RestrictedRoute';

const Routes = () => (
  <Switch>
    {/* Auth */}
    <Route exact path="/auth/accept-invite/:token" component={AuthAcceptInvite} />
    <Route exact path="/auth/forgot-password" component={AuthForgotPassword} />
    <Route exact path="/auth/reset-password/:token" component={AuthResetPassword} />
    <Route exact path="/auth/login" component={AuthLogin} />

    {/* Admin */}
    <AdminRoute exact path="/admin/customer-lookups" component={AdminCustomerLookupsList} />
    <AdminRoute exact path="/admin/users/:id" component={AdminUsersEdit} />
    <AdminRoute exact path="/admin/users" component={AdminUsersList} />
    <Redirect exact path="/admin" to="/admin/users" />

    {/* Console */}
    <Route exact path="/user/settings" component={UserSettings} />

    <RestrictedRoute exact path="/customer-lookup" component={CustomerLookup} permission="CUSTOMER_LOOKUP" />
    <Redirect exact path="/" to="/customer-lookup" />

    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
