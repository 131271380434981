import { format } from 'date-fns';
import ReactJson from 'react-json-view';
import { Button, Icon, Label, Modal, Table } from 'semantic-ui-react';

import { CustomerLookup } from 'src/types';

type Props = {
  lookup: CustomerLookup;
};

const CustomerLookupDetailsModal = ({ lookup }: Props) => (
  <Modal
    trigger={
      <Button title="View Details" icon>
        <Icon name="eye" />
      </Button>
    }
  >
    <Modal.Header>Custom Lookup</Modal.Header>
    <Modal.Content>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>ID</Table.Cell>
            <Table.Cell>{lookup.id}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Created At</Table.Cell>
            <Table.Cell>{format(new Date(lookup.createdAt), 'MMMM d, yyyy @ h:mm:ss a')}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>IP Address</Table.Cell>
            <Table.Cell>{lookup.ipAddress}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>User ID</Table.Cell>
            <Table.Cell>{lookup.user ? lookup.user.email : lookup.userId ?? 'API Secret'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Request Body</Table.Cell>
            <Table.Cell>
              <ReactJson src={lookup.requestBody} collapsed={true} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Response Status Code</Table.Cell>
            <Table.Cell>
              <Label color={lookup.responseStatusCode === 200 ? 'green' : 'red'}>{lookup.responseStatusCode}</Label>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Response Body</Table.Cell>
            <Table.Cell>
              <ReactJson src={lookup.responseBody} collapsed={true} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Modal.Content>
  </Modal>
);

export default CustomerLookupDetailsModal;
