import { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { hasUserPermission } from 'src/utils';
import { MenubarItem, MenubarSpacer, Nav, NavContainer } from './styles';

const MENUBAR_KEY = 'menubar-collapsed';

const useMenubarState = () => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const isCollapsed = localStorage.getItem(MENUBAR_KEY);
    return isCollapsed === 'true';
  });

  const toggleIsCollapsed = useCallback(() => {
    setIsCollapsed(collapsed => {
      localStorage.setItem(MENUBAR_KEY, JSON.stringify(!collapsed));
      return !collapsed;
    });
  }, [setIsCollapsed]);

  return {
    isCollapsed,
    toggleIsCollapsed,
  };
};

const Menubar = () => {
  const { data } = useGetUserProfileQuery();
  const { isCollapsed, toggleIsCollapsed } = useMenubarState();

  const isAdmin = data?.user?.role === 'admin';

  return (
    <NavContainer collapsed={isCollapsed}>
      <Nav collapsed={isCollapsed} className={isCollapsed ? 'collapsed' : ''}>
        {(hasUserPermission(data?.user, 'CUSTOMER_LOOKUP') || isAdmin) && (
          <MenubarItem>
            <NavLink to="/customer-lookup">
              <Icon name="user" />
              <span>Customer Lookup</span>
            </NavLink>
          </MenubarItem>
        )}

        <MenubarSpacer />

        <MenubarItem>
          <NavLink to="#" isActive={() => false} onClick={toggleIsCollapsed}>
            <Icon name={isCollapsed ? 'chevron right' : 'chevron left'} />
            <span>{isCollapsed ? 'Expand' : 'Collapse'}</span>
          </NavLink>
        </MenubarItem>
      </Nav>
    </NavContainer>
  );
};

export default Menubar;
