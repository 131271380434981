import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import { PageContainer, PageHeader } from 'src/styles';
import AdminCustomerLookupsListPane from './AdminCustomerLookupsListPane';

const AdminCustomerLookupsList = () => {
  return (
    <ConsoleLayout hideMenubar>
      <Helmet>
        <title>Customer Lookups - Admin | creditpull</title>
      </Helmet>

      <PageContainer>
        <PageHeader style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'Customer Lookups']} />
        </PageHeader>

        <AdminCustomerLookupsListPane />
      </PageContainer>
    </ConsoleLayout>
  );
};

export default AdminCustomerLookupsList;
