import { useMutation } from 'react-query';

import { ApiResponse, http } from './http-common';

export type CustomerLookupInput = {
  phone: string;
  email: string;
  firstname: string;
  lastname: string;
  address1: string;
  city: string;
  state: string;
  zip: string;
  dob: string;
  ssn: string;
};

export const newCustomerLookupInput = (): CustomerLookupInput => ({
  phone: '',
  email: '',
  firstname: '',
  lastname: '',
  address1: '',
  city: '',
  state: '',
  zip: '',
  dob: '',
  ssn: '',
});

type CustomerLookupResponse = ApiResponse & {
  offerId: string;
  results: {
    [key: string]:
      | string
      | {
          [key: string]: boolean;
        };
  };
};

export const useCustomerLookupMutation = () => {
  return useMutation(async (params: CustomerLookupInput) => {
    const res = await http.post<CustomerLookupResponse>(`/api/customer-lookup`, params);
    return res.data;
  });
};
