import { formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { Button, Label, Table } from 'semantic-ui-react';

import { ListUsersFilters, useListUsersQuery } from 'src/api/admin/users';
import { RenderProps } from 'src/components/PaginatedTable';
import theme from 'src/styles/theme';
import ToggleUserEnabledButton from './ToggleUserEnabledButton';

type Props = RenderProps & {
  filters?: ListUsersFilters;
};

const AdminUsersListBody = ({ currentPage, filters, limit, setPageCount }: Props) => {
  const { data: users } = useListUsersQuery({ limit, offset: limit * (currentPage - 1), ...filters });
  const { push } = useHistory();

  useEffect(() => {
    let count = 1;
    if (typeof users !== 'undefined') {
      count = Math.ceil(users.total / limit);
    }
    setPageCount(count);
  }, [users, limit, setPageCount]);

  return (
    <>
      <Helmet>
        <title>Users - Admin | creditpull</title>
      </Helmet>

      {users?.data.map(user => (
        <Table.Row key={user.id}>
          <Table.Cell collapsing>
            <ToggleUserEnabledButton user={user} />
          </Table.Cell>
          <Table.Cell>
            <Link to={`/admin/users/${user.id}`}>
              <strong>
                {user.firstname} {user.lastname}
              </strong>
            </Link>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
              {user.id}
            </span>
          </Table.Cell>
          <Table.Cell>{user.email}</Table.Cell>
          <Table.Cell>
            <Label color={user.role === 'admin' ? 'blue' : undefined}>{user.role}</Label>
          </Table.Cell>
          <Table.Cell>
            {user.lastLogin && (
              <>
                <strong title={user.lastLogin.createdAt}>
                  {formatDistanceToNow(new Date(user.lastLogin.createdAt), { addSuffix: true, includeSeconds: true })}
                </strong>
                <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
                  {user.lastLogin.ipAddress}
                </span>
              </>
            )}
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <Button icon="pencil" color="blue" onClick={() => push(`/admin/users/${user.id}`)} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default AdminUsersListBody;
