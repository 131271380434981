import { useQuery } from 'react-query';

import { CustomerLookup, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type CustomerLookupsResponse = ApiResponse & {
  lookups: PaginatedResponse<CustomerLookup>;
};

export type ListCustomerLookupsFilters = {
  search?: string;
};

type ListCustomerLookupsQueryParams = ListCustomerLookupsFilters & {
  limit: number;
  offset: number;
};

export const useCustomerLookupsQuery = (
  params: ListCustomerLookupsQueryParams = { limit: 10, offset: 0, search: '' }
) => {
  return useQuery(['customer-lookup', params], async () => {
    const res = await http.get<CustomerLookupsResponse>('/api/admin/customer-lookups', { params });
    return res.data.lookups;
  });
};
