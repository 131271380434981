import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { Button, Form, Header, Label, Segment, Table } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { CustomerLookupInput, newCustomerLookupInput, useCustomerLookupMutation } from 'src/api/customer-lookup';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import ConsoleLayout from 'src/components/ConsoleLayout';
import Loading from 'src/components/Loading';
import { PageContainer, PageHeader } from 'src/styles';
import { cleanPhone } from 'src/utils';

type ValidationErrors = {
  phone?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  address1?: string;
  city?: string;
  state?: string;
  zip?: string;
  dob?: string;
  ssn?: string;
};

const ResultsDisplaySettings: {
  [key: string]: { text: string; hidden: boolean };
} = {
  creditScore: { text: 'Credit score', hidden: false },
  num_autotradelines_6mo: { text: '# of auto trade lines 6 months', hidden: false },
  num_tradelines_24mo: { text: '# of trade lines 24 months', hidden: false },
  total_amount_collections: { text: 'Total amount collections', hidden: false },
  total_balance_revolvingaccts: { text: 'Total balance revolving accts', hidden: false },
  total_balance_unsecured: { text: 'Total balance unsecure', hidden: false },
  total_balance_unsecuredcc: { text: 'Total balance unsecured cc', hidden: false },
  total_creditlimit_openaccts: { text: 'Total credit limit open accts', hidden: false },
  total_creditlimit_opencc: { text: 'Total credit limit open cc', hidden: false },
  total_delinquencies_12mo: { text: 'Total delinquencies 12 months', hidden: false },
  total_monthly_payments: { text: 'Total monthly payments', hidden: false },
  avg_age_tradelines: { text: '', hidden: true },
  num_accountsactive_6mo: { text: '', hidden: true },
  num_cc_used_12mo: { text: '', hidden: true },
  num_nonfederal_studentloans: { text: '', hidden: true },
  num_studentloans_12mo: { text: '', hidden: true },
  num_tradelines: { text: '', hidden: true },
  num_tradelines_6mo: { text: '', hidden: true },
  pct_revolvingtrades_50pct: { text: '', hidden: true },
  pct_revolvingtrades_75pct: { text: '', hidden: true },
  total_auto_payments: { text: '', hidden: true },
  total_balance_autoaccts_12mo: { text: '', hidden: true },
  total_balance_ccaccts: { text: '', hidden: true },
  total_balance_openaccts_12mo: { text: '', hidden: true },
  total_balance_openrevolvingaccts: { text: '', hidden: true },
  total_closed_cctrades: { text: '', hidden: true },
  total_closed_installments: { text: '', hidden: true },
  total_creditlimit_openinstallments: { text: '', hidden: true },
  total_installmentsactive_12mo: { text: '', hidden: true },
  total_studentloanbal_36mo: { text: '', hidden: true },
  total_taxliens_36mo: { text: '', hidden: true },
};

const CustomerLookup = () => {
  const { data: profile, isLoading: userLoading } = useGetUserProfileQuery();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<CustomerLookupInput>(() => newCustomerLookupInput());
  const [results, setResults] = useState<{ key: string; text: string; value: string }[]>();
  const { mutateAsync: lookupCustomer, isLoading: lookupLoading } = useCustomerLookupMutation();

  const onBlurPhone = useCallback(e => {
    const phone = cleanPhone(e.currentTarget.value);
    setFormdata(prev => ({ ...prev, phone }));
  }, []);

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);
    setErrors({});

    try {
      const res = await lookupCustomer(formdata);

      const nextResults: { key: string; text: string; value: string }[] = [];
      Object.keys(res.results).forEach(key => {
        const result = { key, text: '', value: '' };

        if (key in ResultsDisplaySettings) {
          result.text = ResultsDisplaySettings[key].text;
          if (ResultsDisplaySettings[key].hidden) {
            return;
          }
        }

        if (typeof res.results[key] === 'string') {
          result.value = res.results[key] as string;
        } else if (typeof res.results[key] === 'object') {
          if (Object.keys(res.results[key]).length > 0) {
            result.value = Object.keys(res.results[key])[0];
          }
        }

        result.value = result.value.replace(/^option(_?)/i, '');

        nextResults.push(result);
      });

      setResults(nextResults);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);

      const res = e.response && e.response?.data;
      if (res.error && Array.isArray(res.error) && res.error.length > 0) {
        const responseErrors = res.error.reduce((errors: ValidationErrors, err: any) => {
          let param = err.param.toLowerCase();

          if (param.startsWith('address.')) {
            param = param.replace('address.', '');
          }

          if (param === 'street') {
            param = 'address1';
          }

          errors[param as keyof ValidationErrors] = err.msg;
          return errors;
        }, {} as ValidationErrors);
        setErrors(responseErrors);
      }
    }
  }, [formdata, lookupCustomer]);

  const onReset = useCallback(() => {
    setApiMessage(undefined);
    setErrors({});
    setFormdata(newCustomerLookupInput());
    setResults(undefined);
  }, []);

  if (userLoading) return <Loading />;
  if (!profile?.user) return <Redirect to="/auth/login" />;

  return (
    <ConsoleLayout>
      <PageContainer>
        <Helmet>
          <title>Customer Lookup | creditpull</title>
        </Helmet>

        <PageHeader>Customer Lookup</PageHeader>

        <Segment>
          <ApiMessage data={apiMessage} />

          <Form onSubmit={onSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                label="Phone"
                name="phone"
                onChange={onChange}
                value={formdata.phone}
                onBlur={onBlurPhone}
                error={errors.phone}
              />
              <Form.Input label="Email" name="email" onChange={onChange} value={formdata.email} error={errors.email} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="First Name"
                name="firstname"
                onChange={onChange}
                value={formdata.firstname}
                error={errors.firstname}
              />
              <Form.Input
                label="Last Name"
                name="lastname"
                onChange={onChange}
                value={formdata.lastname}
                error={errors.lastname}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Address"
                name="address1"
                onChange={onChange}
                value={formdata.address1}
                error={errors.address1}
              />
              <Form.Input label="City" name="city" onChange={onChange} value={formdata.city} error={errors.city} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input label="State" name="state" onChange={onChange} value={formdata.state} error={errors.state} />
              <Form.Input label="Zip" name="zip" onChange={onChange} value={formdata.zip} error={errors.zip} />
            </Form.Group>
            <Form.Group widths="2">
              <Form.Input
                label="Date of Birth"
                placeholder="YYYY-MM-DD"
                name="dob"
                onChange={onChange}
                value={formdata.dob}
                error={errors.dob}
              />
              {/* <Form.Input
                label="SSN"
                placeholder="123-45-6789"
                name="ssn"
                onChange={onChange}
                value={formdata.ssn}
                error={errors.ssn}
              /> */}
            </Form.Group>

            <Button color="blue" loading={lookupLoading}>
              Submit
            </Button>
            <Button type="button" onClick={onReset}>
              Reset
            </Button>
          </Form>

          {results && (
            <>
              <Header>Response</Header>

              <Table definition>
                <Table.Body>
                  {results.length ? (
                    results.map(r => (
                      <Table.Row key={r.key}>
                        <Table.Cell collapsing>{r.text}</Table.Cell>
                        <Table.Cell>
                          <Label>{r.value}</Label>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell>No results found</Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </>
          )}
        </Segment>
      </PageContainer>
    </ConsoleLayout>
  );
};

export default CustomerLookup;
