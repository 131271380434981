import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';

import { ListCustomerLookupsFilters } from 'src/api/admin/customer-lookup';
import PaginatedTable, { RenderProps } from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import AdminCustomerLookupsListBody from './AdminCustomerLookupsListBody';

const getFiltersFromQuery = (query: URLSearchParams): ListCustomerLookupsFilters => {
  const search = query.get('search') || undefined;

  return { search };
};

const getSearchFromQuery = (query: URLSearchParams): string => {
  return query.get('search') || '';
};

const AdminCustomerLookupsListPane = () => {
  const { push } = useHistory();
  const query = useSearchQuery();
  const [filters, setFilters] = useState<ListCustomerLookupsFilters>(() => getFiltersFromQuery(query));
  const [searchQuery, setSearchQuery] = useState<string>(() => getSearchFromQuery(query));

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const onSubmit = useCallback(() => {
    query.set('search', searchQuery);
    query.set('page', '1');
    push({ search: query.toString() });
  }, [push, query, searchQuery]);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Input
            label="Search"
            placeholder="by request and response body"
            value={searchQuery || ''}
            onChange={(_, { value }) => setSearchQuery(value)}
            onBlur={onSubmit}
          />

          <Form.Button label="&nbsp;" icon>
            <Icon name="search" />
          </Form.Button>
        </Form.Group>
      </Form>

      <PaginatedTable
        headers={['Created At', 'User ID', 'Request', 'Response', '']}
        renderBody={(props: RenderProps) => <AdminCustomerLookupsListBody {...props} filters={filters} />}
      />
    </>
  );
};

export default AdminCustomerLookupsListPane;
