import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { Container, Grid, Header, Segment } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import Loading from 'src/components/Loading';
import { PageContainer, PageHeader } from 'src/styles';
import ChangePasswordForm from './ChangePasswordForm';
import UpdateProfileForm from './UpdateProfileForm';

const UserSettings = () => {
  const { data, isLoading } = useGetUserProfileQuery();

  if (isLoading) return <Loading />;
  if (!data?.user) return <Redirect to="/auth/login" />;

  return (
    <ConsoleLayout hideMenubar>
      <PageContainer>
        <Helmet>
          <title>Settings - User | creditpull</title>
        </Helmet>

        <Container>
          <PageHeader>
            <Breadcrumbs crumbs={['User', 'Settings']} />
          </PageHeader>

          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Segment>
                  <Header>Profile</Header>
                  <UpdateProfileForm />
                </Segment>
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <Header>Change Password</Header>
                  <ChangePasswordForm />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </PageContainer>
    </ConsoleLayout>
  );
};

export default UserSettings;
