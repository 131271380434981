import { format } from 'date-fns';
import { useEffect } from 'react';
import { Label, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { ListCustomerLookupsFilters, useCustomerLookupsQuery } from 'src/api/admin/customer-lookup';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import CustomerLookupDetailsModal from './CustomerLookupDetailsModal';

const ClippedText = styled.span`
  display: block;
  max-width: 400px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type Props = RenderProps & {
  filters?: ListCustomerLookupsFilters;
};

const AdminCustomerLookupsListBody = ({ currentPage, limit, setPageCount, filters }: Props) => {
  const { data: lookups } = useCustomerLookupsQuery({
    ...filters,
    limit,
    offset: limit * (currentPage - 1),
  });

  useEffect(() => {
    let count = 1;
    if (typeof lookups !== 'undefined') {
      count = Math.ceil(lookups.total / limit);
    }
    setPageCount(count);
  }, [lookups, limit, setPageCount]);

  return (
    <>
      {lookups?.data.map(l => (
        <Table.Row key={l.id}>
          <Table.Cell collapsing>
            <strong>{format(new Date(l.createdAt), 'MMMM d, yyyy @ h:mm:ss a')}</strong>
            <span style={{ display: 'block', color: theme.gray }}>{l.ipAddress || 'Unknown'}</span>
          </Table.Cell>
          <Table.Cell>
            <Label color={!l.userId ? 'blue' : undefined}>{l.user ? l.user.email : l.userId ?? 'API Secret'}</Label>
          </Table.Cell>
          <Table.Cell>
            <strong>
              {l.requestBody.phoneNumber} {l.requestBody.firstName} {l.requestBody.lastName}
            </strong>
            <ClippedText style={{ color: theme.gray }}>{JSON.stringify(l.requestBody)}</ClippedText>
          </Table.Cell>
          <Table.Cell>
            <Row style={{ gap: '0.5rem', alignItems: 'center' }}>
              <Label color={l.responseStatusCode === 200 ? 'green' : 'red'}>{l.responseStatusCode}</Label>
              <ClippedText>{JSON.stringify(l.responseBody)}</ClippedText>
            </Row>
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <CustomerLookupDetailsModal lookup={l} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default AdminCustomerLookupsListBody;
