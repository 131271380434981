export type AdminUserInvite = UserInvite & {
  id: string;
  role: string;
  createdAt: string;
  createdById: string;
  createdBy: User | null;
};

export type ArrayInput = {
  appKey: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  emailAddress?: string;
  address: ArrayAddress;
  dob?: string;
  ssn?: string;
  productCode?: string;
  userConsent?: ArrayUserConsent;
};

export type ArrayAddress = {
  street: string;
  city: string;
  state: string;
  zip: string;
};

export type ArrayUserConsent = {
  ipAddress?: string;
  timestamp?: string;
  url?: string;
};

export type CustomerLookup = {
  id: string;
  userId: string | null;
  user: User | null;
  createdAt: string;
  ipAddress: string;
  requestBody: ArrayInput;
  responseStatusCode: number;
  responseBody: Record<string, any>;
};

export const Delimiters = ['Comma', 'Pipe', 'Tab'] as const;

export type Delimiter = typeof Delimiters[number];

export type PaginatedResponse<T> = {
  offset: number;
  limit: number;
  count: number;
  total: number;
  data: T[];
};

export const SortDirections = ['ASC', 'DESC'] as const;

export type SortDirection = typeof SortDirections[number];

export const SortDirectionNames = ['Ascending', 'Descending'] as const;

export const ReportingTimeRanges = [
  'today',
  'yesterday',
  'this week',
  'last week',
  'this month',
  'last month',
  'previous month',
  'last 90 days',
  'this year',
  'last year',
  'custom',
] as const;

export type ReportingTimeRange = typeof ReportingTimeRanges[number];

export const UserPermissions = ['CUSTOMER_LOOKUP'] as const;

export type UserPermission = typeof UserPermissions[number];

export const UserRoles = ['admin', 'user'] as const;

export type UserRole = typeof UserRoles[number];

export type User = {
  id: string;
  email: string;
  role: UserRole;
  permissions: UserPermission[];
  firstname?: string;
  lastname?: string;
  enabled: boolean;
  lastLogin: UserLogin | null;
  // logins: UserLogin[] | null;
};

export type UserInvite = {
  email: string;
  expiresAt: string;
  token: string;
};

export type UserLogin = {
  id: string;
  userId: string;
  user: User | null;
  createdAt: string;
  ipAddress: string;
};

export enum Weekday {
  'Sunday' = 0,
  'Monday' = 1,
  'Tuesday' = 2,
  'Wednesday' = 3,
  'Thursday' = 4,
  'Friday' = 5,
  'Saturday' = 6,
}

export const WeekdayLongNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

export type WeekdayLongName = typeof WeekdayLongNames[number];

export const WeekdayShortNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

export type WeekdayShortName = typeof WeekdayShortNames[number];
